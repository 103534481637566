import type { Entry } from 'contentful'
import type { TypeBaseComponentSkeleton } from '@/types/contentful'

/**
 * @description Composable for determining if components should be displayed
 */
export function useConditionalDisplay() {
  const orderingStore = useOrderingStore()

  /**
   * @description Checks if the promotion should be displayed based on the user's authentication state
   * @param setting - The setting for the promotion
   * @returns {boolean} - Whether the promotion should be displayed
   */
  function relishCheck(
    setting?: Entry<TypeBaseComponentSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']['visibility']
  ): boolean {
    switch (setting) {
      case 'Everyone':
        return true
      case 'Relish':
        return orderingStore.user.isSignedIn
      case 'Guest':
        return !orderingStore.user.isSignedIn
      default:
        return false
    }
  }

  /**
   * @description Checks if the current date is within the active date range
   * @param dateFromString - The start date of the promotion
   * @param dateToString - The end date of the promotion
   * @returns {boolean} - Whether the current date is within the active date range
   */
  function dateRangeCheck(dateFromString: string, dateToString: string): boolean {
    const dateFrom = new Date(Date.parse(dateFromString))
    const dateTo = new Date(Date.parse(dateToString))
    const now = new Date()

    return dateFrom <= now && now <= dateTo
  }

  /**
   * @description Checks if the current day and time is within the active days and time range
   * @param activeDays - The days of the week that the promotion is active
   * @param timeFromHour - The hour that the promotion starts
   * @param timeToHour - The hour that the promotion ends
   * @returns {boolean} - Whether the current day and time is within the active days and time range
   */
  function dayOfWeekAndTimeCheck(activeDays: string[], timeFromHour: number, timeToHour: number): boolean {
    const now = new Date()

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const today = daysOfWeek[now.getDay()]

    const nowHour = now.getHours()

    return activeDays.includes(today) && timeFromHour <= nowHour && nowHour < timeToHour
  }

  /**
   * @description Checks if the user tags match the rule
   * @param userTags {Record<string, Record<string, string>>} - The user's tags
   * @param rule {path: string, value: string} - The tag rule to check from the CMS
   * @returns {boolean} - Whether the user tags match the rule
   */
  function tagCheck(userTags: Record<string, Record<string, string>>, rule: { path: string; value: string }): boolean {
    function getUserTagValue(userTags: Record<string, any>, path: string): string | null | undefined {
      // Keys are separated by colons e.g. 'madbunday:code'
      const keys = path.toLowerCase().split(':')

      let currentObj = userTags

      for (const key of keys) {
        if (Object.hasOwn(currentObj, key)) {
          if (typeof currentObj[key] === 'object') {
            currentObj = currentObj[key]
          } else {
            return currentObj[key]
          }
        } else {
          return undefined
        }
      }
    }

    const tagValue = getUserTagValue(userTags, rule.path.toLowerCase())

    // If the value is 'null', then the user tag should not exist
    if (rule.value === 'null') {
      return tagValue === undefined
    } else {
      // If the value is '*' and the tag has a value, return true
      // Otherwise, check if the user tag value matches the rule value
      return (rule.value === '*' && tagValue !== undefined) || tagValue?.toLowerCase() === rule.value.toLowerCase()
    }
  }

  return {
    dayOfWeekAndTimeCheck,
    dateRangeCheck,
    relishCheck,
    tagCheck
  }
}
