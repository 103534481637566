<script lang="ts" setup>
  import { z } from 'zod'
  import type * as CSS from 'csstype'
  import type { FetchError } from 'ofetch'
  import type { GiftCard, GiftCardLookupRequest } from '~/types/api/gift-cards'
  import { formatPrice } from '~/utils/payment'

  defineProps<{
    backgroundColor?: CSS.Property.BackgroundColor
  }>()

  const validationSchema = toTypedSchema(
    z.object({
      giftCardCode: z
        .string()
        .regex(/^[a-zA-Z0-9\s]*$/, 'Gift Card Code can only contain letters and numbers')
        .min(6, 'Gift Card Code must be at least 6 characters')
        .max(64, 'Gift Card Code must be at most 64 characters'),
      pinCode: z
        .string()
        .regex(/^[a-zA-Z0-9\s]*$/, 'PIN can only contain letters and numbers')
        .min(4, 'PIN must be at least 4 characters')
        .max(64, 'PIN must be at most 64 characters')
    })
  )

  const { values, handleSubmit, resetField, isSubmitting } = useForm({
    validationSchema,
    initialValues: {
      giftCardCode: '',
      pinCode: ''
    }
  })

  function clearField(fieldName: keyof typeof values) {
    if (isSubmitting.value) {
      return
    }

    resetField(fieldName, {
      touched: true,
      value: ''
    })

    if (giftCardAlertResponse.value) {
      giftCardAlertResponse.value = null
    }
  }

  interface GiftCardAlertResponse {
    status: 'success' | 'error'
    message: string
  }

  function getResponseAlert(response: GiftCard | null): GiftCardAlertResponse {
    // If the response is null, return a message that the gift card is invalid
    if (!response) {
      return {
        status: 'error',
        message: 'Hmm, this doesn’t seem to be a valid gift card. Please check your gift card and try again'
      }
    }

    // If the response has an expiry date, return a message that the gift card has expired
    if (response.expiryDateTime < new Date().toISOString()) {
      return {
        status: 'error',
        message: 'This Gift Card has expired'
      }
    }

    // If the response has a balance of 0, return a message that the gift card has no remaining balance
    if (response.balance === 0) {
      return {
        status: 'error',
        message: 'You have $0 remaining'
      }
    }

    // Otherwise, return a message with the current balance
    return {
      status: 'success',
      message: `Your current gift card balance is ${formatPrice(response.balance, true)} and expires on ${new Date(
        response.expiryDateTime
      ).toLocaleDateString()}`
    }
  }

  const giftCardAlertResponse = ref<GiftCardAlertResponse | null>(null)

  const { executeRecaptcha } = useGoogleRecaptcha()

  function onSubmit() {
    handleSubmit(async (values) => {
      if (giftCardAlertResponse.value) {
        giftCardAlertResponse.value = null
      }

      try {
        const response = await $fetch<GiftCard>('/api/gift-cards/lookup', {
          method: 'POST',
          body: {
            code: values.giftCardCode,
            pin: values.pinCode,
            recaptchaResponse: (await executeRecaptcha('submit')) || ''
          } satisfies GiftCardLookupRequest
        })

        giftCardAlertResponse.value = getResponseAlert(response)
      } catch (error: unknown) {
        console.log(error as FetchError<GiftCard>)

        giftCardAlertResponse.value = {
          status: 'error',
          message: 'Hmm, this doesn’t seem to be a valid gift card. Please check your gift card and try again'
        }
      }
    })()
  }
</script>

<template>
  <form class="c-balance-checker" @submit.stop.prevent="onSubmit">
    <CInputIconTextField :disabled="isSubmitting" :maxlength="64" label="Gift Card Code" name="giftCardCode">
      <template #icon>
        <icon-ionic-close-outline
          v-if="values.giftCardCode?.length"
          :class="['icon-close', { 'icon-close--disabled': isSubmitting }]"
          @click="clearField('giftCardCode')"
        />
      </template>
    </CInputIconTextField>

    <CInputIconTextField :disabled="isSubmitting" :maxlength="64" label="PIN" name="pinCode">
      <template #icon>
        <icon-ionic-close-outline
          v-if="values.pinCode?.length"
          :class="['icon-close', { 'icon-close--disabled': isSubmitting }]"
          @click="clearField('pinCode')"
        />
      </template>
    </CInputIconTextField>

    <CAlert v-if="giftCardAlertResponse" :variant="giftCardAlertResponse.status" class="balance-alert">
      <template v-if="giftCardAlertResponse.status === 'error'" #icon>
        <icon-ionic-information-circle height="16px" width="16px" />
      </template>

      <template #default>
        {{ giftCardAlertResponse.message }}
      </template>
    </CAlert>

    <CLoadingButton :loading="isSubmitting" class="button-submit" fluid type="submit" @click="onSubmit">
      Check Balance
    </CLoadingButton>
  </form>
</template>

<style lang="scss" scoped>
  .c-balance-checker {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: v-bind(backgroundColor);

    width: min(100%, 400px);

    .icon-close {
      cursor: pointer;
      width: 1.5rem;
      color: var(--color-text-body);
    }

    .icon-close.icon-close--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .balance-alert {
      margin-top: 0.5rem;
    }

    .button-submit {
      margin-top: 0.5rem;
    }
  }
</style>
