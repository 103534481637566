<script lang="ts" setup>
  import type * as contentful from 'contentful'
  import type { Document } from '@contentful/rich-text-types'
  import type { TypeButtonBlockSkeleton, TypeTextBlockSkeleton } from '@/types/contentful'
  import { withLinebreaks } from '@/utils/string'

  const props = withDefaults(
    defineProps<{
      darkMode?: boolean
      text?: contentful.Entry<TypeTextBlockSkeleton>
      linkButton?: contentful.Entry<TypeButtonBlockSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
      /**
       * Controls the overflow-wrap CSS property on headings screen small and up.
       * @default 'break-word'
       */
      overflowWrap?: 'break-word' | 'normal'
    }>(),
    {
      darkMode: false,
      text: undefined,
      linkButton: undefined,
      overflowWrap: 'break-word'
    }
  )

  const alignAsUsEnglish = computed<'left' | 'center' | 'right' | undefined>(() => {
    switch (props.text?.fields.alignment) {
      case 'Left':
        return 'left'
      case 'Centre':
        return 'center'
      case 'Right':
        return 'right'
      default:
        return undefined
    }
  })
</script>

<template>
  <div class="c-text-block">
    <template v-if="text">
      <div>
        <span v-if="text.fields.overline" class="text-overline" v-html="withLinebreaks(text.fields.overline)" />

        <CDisplayTextNode :dark-mode="darkMode" :text="text" />

        <h2 v-if="text.fields.heading" class="header-3" v-html="withLinebreaks(text.fields.heading)" />
      </div>

      <h3 v-if="text.fields.subtitle" class="header-4" v-html="withLinebreaks(text.fields.subtitle)" />

      <CBodyRichText v-if="text.fields.body" :text="text.fields.body as Document" class="content-body" />
    </template>

    <CLink
      v-if="linkButton"
      :class="['text-link', { 'text-link-dark': darkMode }]"
      :href="linkButton.fields.href"
      :is-embedded-app-link="linkButton.fields.target === 'App'"
      :target="linkButton.fields.target === 'Tab' ? '_blank' : undefined"
    >
      <span>{{ linkButton.fields.title }}</span>
    </CLink>
  </div>
</template>

<style lang="scss" scoped>
  .c-text-block {
    display: flex;
    flex-direction: column;
    gap: 12px;

    text-align: v-bind(alignAsUsEnglish);

    @include screenMdAndUp {
      gap: 16px;
    }
  }

  // eslint-disable-next-line vue-scoped-css/no-unused-selector
  h1,
  h2,
  h3,
  .text-overline {
    color: v-bind('text?.fields.headerColour');
    overflow-wrap: break-word;

    @include screenSmAndUp {
      overflow-wrap: v-bind('overflowWrap');
    }
  }

  .content-body {
    color: v-bind('text?.fields.bodyColour');
  }
</style>
