<script lang="ts" setup>
  import type * as CSS from 'csstype'
  import type { SelectChangeEvent } from 'primevue/select'
  import { useObeeScript } from './CGroupBookingObee.composables'
  import type { GroupBookingRestaurantsResponse, RestaurantGroupOrderingItem } from '~/types/api/group-bookings'

  defineProps<{
    backgroundColor?: CSS.Property.BackgroundColor
  }>()

  const route = useRoute()
  const restaurantIdParam = route.query.restaurantId

  const { data: restaurantsData, error } = await useFetch<GroupBookingRestaurantsResponse>(
    '/api/group-bookings/restaurants'
  )

  if (!restaurantsData.value || error.value) {
    throw createError({
      ...error.value,
      statusCode: 404,
      statusMessage: 'No restaurants with group bookings data. No restaurants will be displayed.'
    })
  }

  const groupedRestaurants = computed(() => {
    if (!restaurantsData.value?.restaurants) {
      return []
    }

    const grouped = restaurantsData.value.restaurants.reduce(
      (acc, restaurant) => {
        const state = restaurant.state

        if (!acc[state]) {
          acc[state] = {
            label: state,
            items: []
          }
        }

        acc[state].items.push(restaurant)

        return acc
      },
      {} as Record<string, { label: string; items: RestaurantGroupOrderingItem[] }>
    )
    return Object.values(grouped)
  })

  const selectedRestaurant = ref<RestaurantGroupOrderingItem | null>(null)

  const { initObee, destroyObee } = useObeeScript(selectedRestaurant)

  function restaurantSelectedHandler(_event: SelectChangeEvent) {
    // when restaurant is selected, reinitialize the obee widget
    destroyObee()
    initObee()
  }

  onMounted(async () => {
    // Perform initialization using the query parameter
    if (restaurantIdParam && typeof restaurantIdParam === 'string' && Number(restaurantIdParam)) {
      const id = Number(restaurantIdParam)
      if (id) {
        const restaurant = restaurantsData.value?.restaurants.find((restaurant) => restaurant.id === id)
        if (restaurant) {
          selectedRestaurant.value = restaurant
          // Initialise the obee widget after the component is mounted
          await nextTick()
          initObee()
        }
      }
    }
  })
</script>

<template>
  <div class="c-group-booking-obee">
    <Select
      v-model="selectedRestaurant"
      :options="groupedRestaurants"
      :pt="{
        root: {
          class: 'custom-select'
        }
      }"
      aria-labelledby="inputLabel"
      filter
      fluid
      option-group-children="items"
      option-group-label="label"
      option-label="label"
      placeholder="Select restaurant"
      @change="restaurantSelectedHandler"
    />
    <div :key="selectedRestaurant?.id" class="obee-widget-wrapper">
      <client-only>
        <div
          :data-obee-url="selectedRestaurant?.obeeId"
          class="obee-widget"
          data-obee-add-height="50"
          data-obee-min-height="450"
          data-obee-scrolltop="true"
          data-obee-widget-type="booking"
        />
      </client-only>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .c-group-booking-obee {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    height: fit-content;

    background-color: v-bind(backgroundColor);
  }

  // eslint-disable vue-scoped-css/no-unused-selector
  .custom-select {
    // Fix for the p-select-fluid class not being correctly defined for the PrimeVue Select component
    // Can be removed when the fis is released in PrimeVue (> 4.2.4)
    width: 100%;
  }

  .obee-widget-wrapper {
    width: min(100%, 652px);
    min-height: 80px;
  }
</style>
