import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'
import type { TypeBaseComponentSkeleton } from './TypeBaseComponent'

export interface TypeGroupBookingChopeComponentFields {
  title: EntryFieldTypes.Symbol
  base: EntryFieldTypes.EntryLink<TypeBaseComponentSkeleton>
}

export type TypeGroupBookingChopeComponentSkeleton = EntrySkeletonType<
  TypeGroupBookingChopeComponentFields,
  'groupBookingChopeComponent'
>
export type TypeGroupBookingChopeComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeGroupBookingChopeComponentSkeleton,
  Modifiers,
  Locales
>

export function isTypeGroupBookingChopeComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeGroupBookingChopeComponent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'groupBookingChopeComponent'
}
