import type InputText from 'primevue/inputtext'
import { useScript } from '@unhead/vue'
import type { RestaurantGroupOrderingItem } from '~/types/api/group-bookings'

/**
 * @description Handles loading the Obee Widget when a restaurant is selected
 */
export function useObeeScript(selectedRestaurant: Ref<RestaurantGroupOrderingItem | null>) {
  const obeeScript = ref()
  const count = ref(1)

  function initObee() {
    if (!selectedRestaurant.value) {
      return
    }

    if (obeeScript.value) {
      obeeScript.value.remove()
    }

    obeeScript.value = useScript({
      src: 'https://obee.com.au/common/js/dynamic/iframe.js',
      async: true,
      key: 'obee-' + count.value + '-' + selectedRestaurant.value.obeeId
    })

    count.value++
  }

  function destroyObee() {
    if (obeeScript.value) {
      obeeScript.value.remove()
    }
  }

  onUnmounted(() => {
    destroyObee()
  })

  return {
    initObee,
    destroyObee
  }
}

type IsDropdownMenuOpen = Ref<boolean>
type ResultItemRefs = Ref<Record<string, Element | null>>
type FilteredResults = ComputedRef<RestaurantGroupOrderingItem[]>
type InputElement = Ref<InstanceType<typeof InputText> | null>

/**
 * @description Keyboard event handlers for the search input
 */
export function useInputHandlers({
  isDropdownMenuOpen,
  resultItemRefs
}: {
  isDropdownMenuOpen: IsDropdownMenuOpen
  resultItemRefs: ResultItemRefs
}) {
  /**
   * @description When the user presses down,
   * focus the first item in the dropdown menu
   */
  function inputKeydownDownHandler() {
    if (isDropdownMenuOpen.value && resultItemRefs.value[0]) {
      ;(resultItemRefs.value[0] as HTMLLIElement).focus()
    }
  }

  /**
   * @description When the user presses esc,
   * close the dropdown menu
   */
  function inputKeydownEscHandler() {
    isDropdownMenuOpen.value = false
  }

  return {
    inputKeydownDownHandler,
    inputKeydownEscHandler
  }
}

/**
 * @description Keyboard event handlers for the dropdown menu items
 */
export function useDropdownItemHandlers({
  filteredResults,
  inputElement,
  isDropdownMenuOpen
}: {
  filteredResults: FilteredResults
  inputElement: InputElement
  isDropdownMenuOpen: IsDropdownMenuOpen
}) {
  const resultItemRefs = ref<Record<string, Element | null>>({})

  /**
   * @description When the user presses tab when the last result is focused,
   * focus the first item
   */
  function dropdownItemKeydownTabHandler(event: Event, index: number) {
    if (index === filteredResults.value.length - 1) {
      event.preventDefault()
      ;(resultItemRefs.value[0] as HTMLLIElement).focus()
    }
  }

  /**
   * @description When the user presses shift + tab when the first result is focused,
   * focus the last item
   */
  function dropdownItemKeydownShiftTabHandler(event: Event, index: number) {
    if (index === 0) {
      event.preventDefault()
      ;(resultItemRefs.value[filteredResults.value.length - 1] as HTMLLIElement).focus()
    }
  }

  /**
   * @description When the user presses up arrow,
   * focus the previous result
   */
  function dropdownItemKeydownUpArrowHandler(index: number) {
    if (index === 0) {
      ;(resultItemRefs.value[filteredResults.value.length - 1] as HTMLLIElement).focus()
    } else {
      ;(resultItemRefs.value[index - 1] as HTMLLIElement).focus()
    }
  }

  /**
   * @description When the user presses down arrow,
   * focus the next result
   */
  function dropdownItemKeydownDownArrowHandler(index: number) {
    if (index === filteredResults.value.length - 1) {
      ;(resultItemRefs.value[0] as HTMLLIElement).focus()
    } else {
      ;(resultItemRefs.value[index + 1] as HTMLLIElement).focus()
    }
  }

  function dropdownItemKeydownEscapeHandler() {
    isDropdownMenuOpen.value = false

    if (inputElement.value) {
      inputElement.value.$el.focus()
    }
  }

  return {
    resultItemRefs,
    dropdownItemKeydownShiftTabHandler,
    dropdownItemKeydownTabHandler,
    dropdownItemKeydownUpArrowHandler,
    dropdownItemKeydownDownArrowHandler,
    dropdownItemKeydownEscapeHandler
  }
}
