import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'
import type { TypeBaseComponentSkeleton } from './TypeBaseComponent'

export interface TypeGiftCardBalanceCheckerComponentFields {
  title: EntryFieldTypes.Symbol
  base: EntryFieldTypes.EntryLink<TypeBaseComponentSkeleton>
}

export type TypeGiftCardBalanceCheckerComponentSkeleton = EntrySkeletonType<
  TypeGiftCardBalanceCheckerComponentFields,
  'giftCardBalanceCheckerComponent'
>
export type TypeGiftCardBalanceCheckerComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeGiftCardBalanceCheckerComponentSkeleton,
  Modifiers,
  Locales
>

export function isTypeGiftCardBalanceCheckerComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeGiftCardBalanceCheckerComponent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'giftCardBalanceCheckerComponent'
}
