import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'
import type { TypeBaseComponentSkeleton } from './TypeBaseComponent'

export interface TypeGroupBookingObeeComponentFields {
  title: EntryFieldTypes.Symbol
  base: EntryFieldTypes.EntryLink<TypeBaseComponentSkeleton>
}

export type TypeGroupBookingObeeComponentSkeleton = EntrySkeletonType<
  TypeGroupBookingObeeComponentFields,
  'groupBookingObeeComponent'
>
export type TypeGroupBookingObeeComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeGroupBookingObeeComponentSkeleton,
  Modifiers,
  Locales
>

export function isTypeGroupBookingObeeComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeGroupBookingObeeComponent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'groupBookingObeeComponent'
}
